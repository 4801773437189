._2knZ06i4oQjVh1D-wotlCm {
  display: inline;
}

._3rUZEpjbIqlWtVs1mLf81R {
  fill: var(--color-content-highlight-hover);
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right: 10px;
}

._3rUZEpjbIqlWtVs1mLf81R svg {
  fill: var(--color-content-highlight-hover);
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

._10h_9QwFVIdvCLPzruKOvX {
  vertical-align: middle;
}
