.ZfVfLPrJQwn3rxmaIXm65 {
  display: inline-block;
  max-width: 85%;
  height: 20px;
  position: relative;
  font-size: 12px;
  z-index: 10;
  padding: 5px;
}

._2aptexKwaUiCdZN-wwJfnx {
  top: 10px;
  left: 10px;
}

._1ZIjP5W0cmuo8R5loUirh7 {
  overflow: hidden;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  line-height: 1;
  text-shadow: none;
}

@media (hover: hover) and (pointer: fine) {
  ._2aptexKwaUiCdZN-wwJfnx:hover::before, ._2aptexKwaUiCdZN-wwJfnx:hover::after {
    display: block;
  }
}
._2aptexKwaUiCdZN-wwJfnx::before {
  text-align: center;
  content: attr(data-tool-tip);
  display: none;
  width: max-content;
  position: absolute;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 4px 8px;
  background-color: #1d1e1c;
  color: white;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  transition: opacity ease-out 150ms, bottom ease-out 150ms;
  bottom: 140%;
  left: -14px;
}

._2aptexKwaUiCdZN-wwJfnx::after {
    content:'';
    width:0;
    height:0;
    position:absolute;
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #1d1e1c 10px;
    left:20%;
    bottom: 100%;
    display: none;
}