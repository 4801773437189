._2qHcURT_FJNHkNU9D7Bku2 {
  position: relative;
  margin: 0 var(--marginBetweenThumbnails) 0 0;
}

._1VX1Glt2PBwdwuZl4AtQwb {
  position: relative;
  transition: all 250ms cubic-bezier(.21, .43, .37, .99);
}

._3vljgWbEh1OCxG2jTGWzQ- {
  transform: scale3d(1.1, 1.1, 1.1);
  /* overflow: hidden; */
  /* clip-path: inset(0 0 0 round var(--border-radius-thumbnail-medium)); */
}

._2oRokDW7MlCcEq8yxG6bMf {
  position: relative;
  clip-path: inset(0 0 0 round var(--border-radius-thumbnail-medium));
}

._2u4Tn5c5HnO27UsZzGzQBU {
  background: none;
}

._86LfqnBkVliNOjJudT571 {
  position: absolute;
  bottom: 0;
}

._1ql57MhfJj4rQd-_l5eHg3 {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 50;
  width: 40px;
  height: 40px;
  text-align: left;
}

._2JfkBxTfxHjwZh-9oGy8FY {
  position: relative;
  z-index: 50;
  margin: auto;
  vertical-align: top;
  max-width: 40px;
  max-height: 40px;
}

._2kDEoKJ4iEshm_5hr1s0p9 {
  text-decoration: none;
  display: block;
}

._3YWQsAgYLB2bljUiteKTz5 {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
}

._202kVQio3gfoNUfap6z3Qn {
  fill: var(--color-thumbnail-highlight-hover1);
}

._omsV45RBPxjDCd8vUEM7 {
  border-radius: var(--border-radius-thumbnail-small);
}

._2uniG6aA-eBAgfQBtQGj5a {
  border-radius: var(--border-radius-thumbnail-medium);
}

._2aljjxe9JybTo-CSGNOA48 {
  border-radius: var(--border-radius-thumbnail-large);
}

.Q0BGoHP5r7yVA7UgGg081 {
  box-shadow: var(--shadow-thumbnail-small);
}

._3nHoDh44MMsNAcAVQzXKb9 {
  box-shadow: var(--shadow-thumbnail-medium);
}

.pheT-SJjfbhaNfYjmb2IO {
  box-shadow: var(--shadow-thumbnail-large);
}