._2F_NTA19j04UzSYqGjU1xn {
  cursor: pointer;
  text-decoration: none;
  margin: 0 var(--marginBetweenThumbnails) 0 0;
  position: relative;
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
  /* overflow: hidden; */
  min-height: 266px;
  min-width: 200px;
  transition: opacity 1s;
}

._2Mgwy4JoycbVel4fGCfD6_ {
  border-radius: var(--border-radius-thumbnail-small);
}

._3APkHR2TQloUuWOLonwII_ {
  border-radius: var(--border-radius-thumbnail-medium);
}

.J83HMXAQkp7Wx5e72ijw8 {
  border-radius: var(--border-radius-thumbnail-large);
}

._2W7hLPnjSvRbEjlRzyfgAK {
  box-shadow: var(--shadow-thumbnail-small);
}

.C3Vb1ofQAKlNbjmDA9YYA {
  box-shadow: var(--shadow-thumbnail-medium);
}

._3x_6yaXdqumWQ3WFkWlop6 {
  box-shadow: var(--shadow-thumbnail-large);
}

._1Xjk5ws70PRxw0AKqcaxiS {
  margin: 0;
}
/* poster zoom */
._3BtfIc5pBl1eyQu9zXzfCF {
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
  transform: scale3d(1.1, 1.1, 1.1);
}
._2eO3peP27u-97Uh5vKKQMi {
  transform: scale(1);
  animation: _3XfRUdJSM7P_Eg-5o6uDUM 250ms 100ms;
}
._1IXy_d_vJHvjfPw4zq69bh {
  width: 140%;
  max-width: 245px;
  z-index: 1000;
  background-color: black;
  /* transform: scale3d(1.4, 1.4, 1.4); */
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  transform: scale(1.1);
  left: -40%;
  -webkit-transition: transform 0.02ms;
  -moz-transition: transform 0.02ms;
  -o-transition: transform 0.02ms;
  transition: transform 0.02ms;
}

.UZ64tWt7BZllTbMdG0mhh {
  width: 140%;
  max-width: 245px;
  z-index: 1000;
  background-color: black;
  /* transform: scale3d(1.4, 1.4, 1.4); */
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  transform: scale(1.1);
  margin-left: -40px;
  -webkit-transition: transform 400ms;
  -moz-transition: transform 400ms;
  -o-transition: transform 400ms;
  transition: transform 400ms;
}

.-qVFQh2p1WxHblZDSVTj0 {
  cursor: pointer;
  text-decoration: none;
  margin: 0 var(--marginBetweenThumbnails) 0 0;
  position: relative;
  overflow: hidden;
  min-height: 266px;
  min-width: 200px;
  transform: scale(0.9);

  -webkit-transition: transform 250ms 100ms;
  -moz-transition: transform 250ms 100ms;
  -o-transition: transform 250ms 100ms;
  transition: transform 250ms 100ms;
}

._2iWEOrdUrB-MfIBznEWly3 {
  position: relative;
  overflow: hidden;
}

._2iWEOrdUrB-MfIBznEWly3 > img {
  object-fit: contain;
}

._1L5KxnfOVveIxEEyp88Rlt {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 15;
  width: 40px;
  height: 40px;
}

.cVcfZxgg0mHVdLwI33zPy,
._1rwryylAl9p9EXoaYTmT2m {
  position: absolute;
  bottom: 0;
  z-index: 50;
}
._1VjhVuYAsiDYlXLij3UcIN {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 50;
  width: 40px;
  height: 40px;
  text-align: left;
}
._1K1aC9FbuVMm3435ZyNz8_ {
  position: relative;
  z-index: 50;
  margin: auto;
  vertical-align: top;
  max-width: 40px;
  max-height: 40px;
}
.UZ64tWt7BZllTbMdG0mhh .cVcfZxgg0mHVdLwI33zPy {
  bottom: -2px;
}

.UZ64tWt7BZllTbMdG0mhh ._1rwryylAl9p9EXoaYTmT2m {
  bottom: 0;
}

._24YysRUr5-ZjZguXZCGyX5 {
  background: var(--color-content-overlay3);
}
._349ueiFpwr13o1NZraULrI {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
}
._10DQnVz7tIpNTz7UzPDw2V {
  fill: var(--color-thumbnail-highlight-hover1);
}
