:root {
  --mobile: 50em;
}

._1WQUiSxYDxEsW4ALAmaDTK {
  margin: 20px;
  width: 60px;
  height: 60px;
}

._1Gmjy_LQOvO8RvNUoRnnkt {
  position: fixed;
  top: 0;
  bottom: 0;
  background: var(--color-nav-background);
  transition: all 300ms ease-in-out;
  z-index: 1;
}

._3Ea0zQpbRb83hUuQuPfpL5 {
  left: 0;
}

.-vsuUSKYNulok7VxMHg07 {
  right: 0;
}

._16l-Tfr78ZvhQg8UXBMm1Z {
  display: inline-block;
  position: relative;
  top: 8px;
  left: 0;
}

._3rH2U6AhOKK76B4_j2e4ID {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 60px;
  background: var(--color-nav-toggle-default);
  border: none;
  color: var(--color-nav-toggle-icon-default);
  border-radius: 3px;
}

._3rH2U6AhOKK76B4_j2e4ID._2-O8qpCT_67e5Fdqmabo8K {
  right: -10px;
}
._3rH2U6AhOKK76B4_j2e4ID._8FFU2Y0Tkrn0vDSbCiUtV {
  left: -10px;
}

._3rH2U6AhOKK76B4_j2e4ID:focus {
  outline: 0;
}

._3rH2U6AhOKK76B4_j2e4ID:hover {
  background: var(--color-nav-toggle-hover);
  cursor: pointer;
}

._3rH2U6AhOKK76B4_j2e4ID:hover ._24TFENywZ0IzIitH-vqGJA path {
  stroke: var(--color-button-normal-font-hover);
}

._3J0nDLSIcfVSPYFbkVSCSO {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: var(--fontSize-s);
  margin-top: 10px;
}

._2TN8AKUYuhlj3iGgftbBWl ._1N84qmTCj3vAFnsWRIn7I_ path,
._2TN8AKUYuhlj3iGgftbBWl ._1N84qmTCj3vAFnsWRIn7I_ svg,
._2TN8AKUYuhlj3iGgftbBWl ._1N84qmTCj3vAFnsWRIn7I_ svg * {
  stroke: var(--color-nav-primary3);
  fill: var(--color-nav-primary3);
}

._3J0nDLSIcfVSPYFbkVSCSO:hover .xxHTX4uoZwaxtgCPb14jF {
  color: var(--color-nav-hover);
}

._3J0nDLSIcfVSPYFbkVSCSO:hover ._2TN8AKUYuhlj3iGgftbBWl ._1N84qmTCj3vAFnsWRIn7I_ path,
._3J0nDLSIcfVSPYFbkVSCSO:hover ._2TN8AKUYuhlj3iGgftbBWl ._1N84qmTCj3vAFnsWRIn7I_ svg,
._3J0nDLSIcfVSPYFbkVSCSO:hover ._2TN8AKUYuhlj3iGgftbBWl ._1N84qmTCj3vAFnsWRIn7I_ svg * {
  stroke: var(--color-nav-hover);
  fill: var(--color-nav-hover);
}

._2TN8AKUYuhlj3iGgftbBWl {
  display: inline-block;
  vertical-align: middle;
  margin: 0 38px;
}

._1N84qmTCj3vAFnsWRIn7I_ > svg {
  width: 20px;
  height: 20px;
  /* transition: all 0.3s ease-in-out; */
}

._1N84qmTCj3vAFnsWRIn7I_ path,
._1N84qmTCj3vAFnsWRIn7I_ svg,
._1N84qmTCj3vAFnsWRIn7I_ svg * {
  stroke: var(--color-nav-toggle-icon-default);
  fill: var(--color-nav-toggle-icon-default);
  stroke-width: 1.5px;
  /* transition: all 300ms ease-in-out; */
}

._1OdS8n6p0YUgNpAKjYUv8K > svg {
  transform: rotate(-90deg);
}

._2C8RPnXsvyvicnsKf5J7pc > svg {
  transform: rotate(90deg);
}

._3YY7wD4ScGZMxeAFHGuENM {
  height: calc(100vh - 230px - 150px); /* 230px = top & 150px = bottom */
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: 30px;
}

._3l31neW01XMMSDtAHgK2n4 {
  padding-right: 0;
}

._3YY7wD4ScGZMxeAFHGuENM:hover {
  overflow-y: auto;
}

._3OQIoIT4R-iHLqSZ100zk9 {
  overflow-x: hidden;
  overflow-y: hidden;
  height: calc(100vh - 260px); /* 260px = top */
}
._3OQIoIT4R-iHLqSZ100zk9:hover {
  overflow-y: auto;
}

._24TFENywZ0IzIitH-vqGJA > svg {
  width: 24px;
  height: 24px;
  position: relative;
  stroke-width: 1.5px;
  stroke: var(--color-nav-primary3);
}

._3rHCLtxa3JxMI2N8F2DcqB > svg {
  left: -8px;
  top: -2px;
}

._2zpkJq8zz_lndmPxuZ1BLi > svg {
  transform: rotate(180deg);
  left: -10px;
  top: 6px;
}

.rbQ_1G_MUTkpGwZRoYAXs > svg {
  right: -8px;
  top: 5px;
  transform: rotate(180deg);
}

._3Cn-TVtzq6FCtKCBzv04s0 > svg {
  transform: rotate(0deg);
  right: -10px;
  top: -2px;
}

._2aTBh7pEP9rj544L9SZzTw,
._1dRUSLw9NbQjWubrQFObIY {
  margin-top: 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: relative;
  left: -15px;
  top: -15px;
}

._2Th-4TGZyfVptj9_OWXKf5 {
  color: var(--color-nav-primary1);
}

._2Th-4TGZyfVptj9_OWXKf5:hover {
  color: var(--color-nav-hover);
}

div._2aTBh7pEP9rj544L9SZzTw,
div._1dRUSLw9NbQjWubrQFObIY {
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

/* div.accountImage {
  background-color: var(--color-nav-hover);
} */

div._2aTBh7pEP9rj544L9SZzTw span {
  color: #ffffff;
  font-size: 19px;
  font-weight: bolder;
  margin-top: 11px;
  display: inline-block;
  text-transform: uppercase;
}

._2lkRa9tuJlj_MEOSwswwyO {
  background: transparent url(/assets/profile_default.png)
    no-repeat;
  background-size: 50px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

._3TKAnbXjyBPeslnuNqUq1h {
  position: absolute;
  top: 35px;
  left: 35px;
}

._1y7oIS8dAZJg2V01TogGuO {
  display: none;
}

._3N0XcD9bCFf8bgSbx_Ru4K {
  position: absolute;
  bottom: 0;
  background: var(--color-nav-background);
  width: 100%;
}

._2WV0A2FBs95qPIhfwPtCDH fieldset {
  margin-left: 10px;
}

._2WV0A2FBs95qPIhfwPtCDH fieldset details summary span:nth-child(2) {
  margin-left: 30px;
  opacity: 1;
  transition: opacity 400ms;
}
._2WV0A2FBs95qPIhfwPtCDH._2pQ0MjsRpURFltNsukebl5 fieldset details summary span:nth-child(2) {
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms;
}

._121bk56i1lD3BVm1X6B8cU {
  overflow: hidden !important;
}

._2WV0A2FBs95qPIhfwPtCDH fieldset details > div {
  bottom: 50px;
}

.xxHTX4uoZwaxtgCPb14jF {
  color: var(--color-primary3);
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 90px;
}

._227clUeD-Pzaay_de52ey0 {
  opacity: 0;
  visibility: visible;
}

._2j5fMtrLS0MlwUmTSgoEIg {
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0.1s;
  opacity: 1;
  visibility: visible;
}
._1u1YTM2tCG4FIDeWUYlcme {
  opacity: 1;
  visibility: visible;
}

._2P-TJEcZTTe-iNhLJvZyso {
  opacity: 1;
  visibility: visible;
  transition: opacity 200ms;
}

._17ir3nv4y4QPMjbasmN3d_ {
  opacity: 0;
  transition: opacity 200ms;
}

._2fVLT1gPS3gUEqKjrRzsPG {
  visibility: hidden;
}

._3kZJ-mrXI1h2OYwhoTcvbn {
  margin: 20px;
  height: 60px;
  object-fit: contain;
}

.crAPCPVb8P0e5-76t0sbb {
  height: 30px;
}

@media (max-width: 1023px) {
  ._1y7oIS8dAZJg2V01TogGuO {
    display: block;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: var(--color-nav-background);
  }

  ._1tITX6uP3CqFgVSpAOYE8O,
  ._3kZJ-mrXI1h2OYwhoTcvbn {
    height: 46px;
    margin-top: 7px;
    margin-left: 50px;
  }

  ._3eBdNqK5qBdjyv-jrXcGlH {
    margin-right: 50px;
    margin-left: 0px;
  }

  ._1tITX6uP3CqFgVSpAOYE8O.-vsuUSKYNulok7VxMHg07,
  ._3kZJ-mrXI1h2OYwhoTcvbn.-vsuUSKYNulok7VxMHg07 {
    margin-left: 0;
    margin-right: 50px;
  }

  ._3uV77TuhHFfVql4U6UAtnP {
    position: relative;
    top: 70px;
    height: calc(100% - 70px);
  }

  ._1vWgUKDfDaLM4gLcw3ynWc {
    fill: var(--color-nav-primary1);
    position: absolute;
    left: 10px;
    top: 18px;
    cursor: pointer;
  }

  ._1vWgUKDfDaLM4gLcw3ynWc.-vsuUSKYNulok7VxMHg07 {
    left: 0;
    right: 10px;
  }

  ._1WQUiSxYDxEsW4ALAmaDTK,
  ._3kZJ-mrXI1h2OYwhoTcvbn,
  .i_sY20xHf4Fr5c8uJg_MF {
    display: none !important;
  }

  ._3rH2U6AhOKK76B4_j2e4ID {
    display: none;
  }
}

@media (max-width: 767px) {
  ._1evNR1-TNMt9i7e05cBXN {
    right: 90px;
    left: 0;
  }
}
