:root {
  --marginBetweenThumbnails: 20px;
}
._1V0FV_fjweYfHJQMxqys9M {
  display: block;
  margin: 5px 0 35px 0;
  position: relative;
}

._1tOhwXG8KfJDBB2JZKp_2f {
  text-decoration: none;
}

._1tOhwXG8KfJDBB2JZKp_2f:hover .gSfL5FqWaFIDCRjYRe_vs {
  color: var(--color-highlight-hover1);
}

._1tOhwXG8KfJDBB2JZKp_2f:hover ._1tRkAn7DVBJTLHBplD4qIN {
  fill: var(--color-highlight-hover1);
}

.gSfL5FqWaFIDCRjYRe_vs {
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-content-default1);
  margin-bottom: 0;
}

.gSfL5FqWaFIDCRjYRe_vs.REgRqN0XCKk0ED77F-dMI::first-letter {
  text-transform: uppercase;
}

.gSfL5FqWaFIDCRjYRe_vs:hover ._2bN8qYtdyqH9U-YMIm_1Cl.REgRqN0XCKk0ED77F-dMI {
  transform: translateX(10px);
}
.gSfL5FqWaFIDCRjYRe_vs:hover ._2bN8qYtdyqH9U-YMIm_1Cl._2PfYrtX3v7ECJrNyey8F_G {
  transform: translateX(-10px);
}

._2bN8qYtdyqH9U-YMIm_1Cl {
  position: absolute;
  right: 0;
  top: 0;
  font-size: var(--fontSize-s);
  font-weight: normal;
  line-height: 26px;
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
}

._2PfYrtX3v7ECJrNyey8F_G {
  right: unset;
  left: 0;
}

._2PfYrtX3v7ECJrNyey8F_G ._1tRkAn7DVBJTLHBplD4qIN {
  float: left;
  margin-right: 10px;
  transform: rotate(180deg);
}

._1tRkAn7DVBJTLHBplD4qIN {
  float: right;
  margin-left: 10px;
  fill: var(--color-content-default1);
}

._2XxY5o0Ge8NGbm9MPPPCvF {
  width: 50px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 50%;
  background-color: var(--color-button-arrow-bg);
  text-align: center;
  font-size: 20px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: all 0.25s cubic-bezier(0.21, 0.43, 0.37, 0.99);
  cursor: pointer;
}

._3S1zDVber9rVCozcJfPuAM {
  width: 50px;
  height: 50px;
  position: absolute;
  right: -25px;
  top: 50%;
  background-color: var(--color-button-arrow-bg);
  text-align: center;
  font-size: 20px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  transition: all 0.25s cubic-bezier(0.21, 0.43, 0.37, 0.99);
}

.bwJyv_SJRk0Wf1gwG7cc9,
.bwJyv_SJRk0Wf1gwG7cc9 > svg {
  stroke: var(--color-button-arrow-icon);
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

._2XxY5o0Ge8NGbm9MPPPCvF:hover,
._3S1zDVber9rVCozcJfPuAM:hover {
  background-color: var(--color-button-arrow-bg-hover);
}

._2XxY5o0Ge8NGbm9MPPPCvF:hover .bwJyv_SJRk0Wf1gwG7cc9 > svg,
._3S1zDVber9rVCozcJfPuAM:hover .bwJyv_SJRk0Wf1gwG7cc9 > svg {
  stroke: var(--color-button-arrow-icon-hover);
}

._2XxY5o0Ge8NGbm9MPPPCvF .bwJyv_SJRk0Wf1gwG7cc9 > svg {
  transform: rotate(180deg);
}

._2fgwInmtjhnCyfZdqPCoQQ {
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  padding: 15px;
}

._3tpXBqcRO6RqTcyTWqWi_V {
  display: flex;
  transition: transform 0.8s;
}

.zz-eac4I3a3JXeXa9pnxj {
  overflow: hidden;
  padding: 40px 20px 20Px 40px;
  margin-top: -10px;
  margin-left: -40px;
  outline: none;
}

@media (max-width: 767px) {
  .gSfL5FqWaFIDCRjYRe_vs {
    font-size: var(--fontSize-m);
  }

  ._1V0FV_fjweYfHJQMxqys9M {
    margin: 0;
  }
}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

._1R3gesRBAo31XoyxF9jQhP {
  position: relative;
}

._1R3gesRBAo31XoyxF9jQhP:focus {
  outline: none;
}

/*.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}*/

.t0Lz8LrbSLEC0Um7tUsPF {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

._1R3gesRBAo31XoyxF9jQhP._1H0xCdGXpLBjUTeZi5XECu {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

._1R3gesRBAo31XoyxF9jQhP._1H0xCdGXpLBjUTeZi5XECu ._140TXINDFgM6f9jObJv92I {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

._1R3gesRBAo31XoyxF9jQhP._1H0xCdGXpLBjUTeZi5XECu ._140TXINDFgM6f9jObJv92I._1XSthg5wR9hLmPpDRFkYqa {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

._2iJem1HiWIV7SV5kdddFY6 {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

._2iJem1HiWIV7SV5kdddFY6:hover {
  background: white;
  cursor: pointer;
}

._2iJem1HiWIV7SV5kdddFY6:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

._2iJem1HiWIV7SV5kdddFY6:active {
  opacity: 0.6;
}

._2iJem1HiWIV7SV5kdddFY6:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.o6AZFE1xun5dn06JswrzK {
  fill: #333;
}

/* ---- previous/next buttons ---- */

._3rWOBjUI1ucX0U8VHnc9Gw {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

._3rWOBjUI1ucX0U8VHnc9Gw._1TGahFBHDbnkOEilnoy3s4 {
  left: 10px;
}
._3rWOBjUI1ucX0U8VHnc9Gw._2WtxB2YO3dFXXK50xHAflu {
  right: 10px;
}
/* right to left */
._1ZJ1yH9JcnGBXXfnL12BHz ._3rWOBjUI1ucX0U8VHnc9Gw._1TGahFBHDbnkOEilnoy3s4 {
  left: auto;
  right: 10px;
}
._1ZJ1yH9JcnGBXXfnL12BHz ._3rWOBjUI1ucX0U8VHnc9Gw._2WtxB2YO3dFXXK50xHAflu {
  right: auto;
  left: 10px;
}

._3rWOBjUI1ucX0U8VHnc9Gw .o6AZFE1xun5dn06JswrzK {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.hBchDg5AbxmU8R-wDt7h2 {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

._1ZJ1yH9JcnGBXXfnL12BHz .hBchDg5AbxmU8R-wDt7h2 {
  direction: rtl;
}

.hBchDg5AbxmU8R-wDt7h2 ._1J2jTiYoI5fSPBpH--Ubfk {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.hBchDg5AbxmU8R-wDt7h2 ._1J2jTiYoI5fSPBpH--Ubfk.dDd8qTvZO4Kgd4CkebW8G {
  opacity: 1;
}
