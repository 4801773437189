:root {
  --height: 40px;
  --fontSize: var(--fontSize-l);
  --maxWidth: 440px;
}

.BDTUTTam5OuLCF8QyJlfH {
  border: none;
  border-bottom: 1px solid var(--color-input-border-bottom);
  color: var(--color-input-primary);
  height: var(--height);
  background: transparent;
  font-size: var(--fontSize);
  padding: 0 5px;
  outline: transparent;
  max-width: var(--maxWidth);
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

._3PP7wfy9E9Zmdj_1bSCNGA {
  color: var(--color-login-input-primary);
  border-bottom: 1px solid var(--color-login-input-border-bottom);
}

.BDTUTTam5OuLCF8QyJlfH:focus {
  border-bottom-color: var(--color-input-border-bottom-focus);
}

._3PP7wfy9E9Zmdj_1bSCNGA:focus {
  border-bottom-color: var(--color-login-input-border-bottom-focus);
}

._3PP7wfy9E9Zmdj_1bSCNGA::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--color-login-input-placeholder);
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--color-input-placeholder);
}
::-moz-placeholder { /* Firefox 19+ */
  color: var(--color-input-placeholder);
}
:-ms-input-placeholder { /* IE 10+ */
  color: var(--color-input-placeholder);
}
:-moz-placeholder { /* Firefox 18- */
  color: var(--color-input-placeholder);
}

._1DpI58zEEiBKxrfROh6fP0 {
  position: relative;
}

.x9hb4V_wul9tdn8yXuQse {
  cursor: pointer;
  position: absolute;
  top: 4px;
  fill: var(--color-content-default3);
  width: 25px;
  height: 25px;
}

.x9hb4V_wul9tdn8yXuQse._3lMX9BdTH1mutjsukwF-yO{
  left: 0;
  top: 0;
}

.x9hb4V_wul9tdn8yXuQse.KZLyAWvNIQsV2egCi_IVm{
  right: 0;
}

input:disabled {
  color: var(--color-content-default3);
}

input[type="number"] {
  position: relative;
  margin-top: 10px;
}

input[type="number"]:focus {
  border-bottom-color: var(--color-input-border-bottom);
}

/* Spin Buttons modified */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTEyIDQuNzU3TDE2LjI0MyA5SDcuNzU3TDEyIDQuNzU3em0wIDE0LjQ4Nkw3Ljc1NyAxNWg4LjQ4NkwxMiAxOS4yNDN6Ii8+Cjwvc3ZnPgo=) no-repeat center center;
  width: 1em;
  opacity: .5; /* shows Spin Buttons per default (Chrome >= 39) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
input[type="number"]::-webkit-inner-spin-button:hover,
input[type="number"]::-webkit-inner-spin-button:active{
  box-shadow: 0;
  opacity: .8;
}

/* Override browser form filling */
input:-webkit-autofill {
  background: black;
  color: red;
}

/* REMOVE DEFAULT CSS OVERWRITE BY BROWSER */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
}

._113SDe3ei6FgAR60QcHyYE {
  display: block;
  margin-top: 10px;
  color: var(--color-error);
  font-size: var(--fontSize-s);
}
