._2SdL-elnkHx6Hy0mRkekK9 {
  display: flex;
}

.a0SWJwdB1Bzw7YGJirMql {
  display: flex;
  align-items: center;
}

.a0SWJwdB1Bzw7YGJirMql details:first-of-type {
  z-index: 9999999;
}

.a0SWJwdB1Bzw7YGJirMql div:last-child {
  margin-right: 0;
}

.VO3a96t60uNkkFpVLhaPP {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.VO3a96t60uNkkFpVLhaPP div {
  margin-right: 10px;
}

.VO3a96t60uNkkFpVLhaPP div:last-child {
  margin-right: 0;
}

._3M2svAkLEuuahIX9Ra9nxD {
  text-decoration: none;
  color: var(--color-select-primary1);
  font-weight: bold;
  width: 100%;
  margin-right: 30px;
  cursor: pointer;
}

._3M2svAkLEuuahIX9Ra9nxD:hover {
  color: var(--color-content-highlight-hover);
}

._19cexDVIaGdZ_5XdJu91up > svg {
  width: 1px;
  height: 40px;
  background-color: var(--color-nav-separator);
  margin: 0 30px;
}

._2w4KoDSn0GU24xqNY-4ABS > svg,
.AiWzzy-3u3RtKqhlK8gQ5 > svg,
._214ChKU5FhWfp7QuWEDCqZ > svg,
._2cbL8MrwP63nCpFgNFwefw > svg {
  height: 24px;
  width: 24px;
}

._25txAFJUYSJ78HoYNzmdtA {
  width: 40px;
  height: 40px;
  background-color: var(--color-select-background);
  border-radius: 5px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  stroke: var(--color-select-primary1);
  fill: var(--color-select-primary1);
}

._3o8qWGJnvnWUE9BNejj-q6 {
  width: 40px;
  height: 40px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

._3o8qWGJnvnWUE9BNejj-q6 fieldset details div {
  right: -110px;
}

._25txAFJUYSJ78HoYNzmdtA:hover {
  background-color: var(--color-select-background-hover);
}

._1U3o7cfFZOXlBTBhNAnEEJ path {
  fill:var(--color-content-default3);
}

._214ChKU5FhWfp7QuWEDCqZ > svg {
  transform: rotate(180deg);
}

._25txAFJUYSJ78HoYNzmdtA:hover ._2w4KoDSn0GU24xqNY-4ABS > svg,
._25txAFJUYSJ78HoYNzmdtA:hover .AiWzzy-3u3RtKqhlK8gQ5 > svg,
._25txAFJUYSJ78HoYNzmdtA:hover ._214ChKU5FhWfp7QuWEDCqZ > svg,
._25txAFJUYSJ78HoYNzmdtA:hover ._2cbL8MrwP63nCpFgNFwefw > svg {
  stroke: var(--color-button-normal-font-hover);
  fill: var(--color-button-normal-font-hover);
}

._3oc203PqonHp-ntJ51lfFE {
  min-width: 160px;
  height: 30px;
  padding: 6px 10px 6px 20px;
  border-radius: 5px;
  background-color: var(--color-select-background);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  cursor: pointer;
  stroke: var(--color-select-primary1);
  fill: var(--color-select-primary1);
}

._3oc203PqonHp-ntJ51lfFE:hover {
  background-color: var(--color-select-background-hover);
}

._2fJTD83YhEdcqVn9JOp8s6 {
  font-size: var(--fontSize-l);
  font-weight: bold;
  cursor: pointer;
  margin-right: 5px;
}

._2T2TswLviShbQkctTkhe09 {
  cursor: pointer;
  width: 20px;
}

._2T2TswLviShbQkctTkhe09 path {
  stroke: var(--color-content-default3);
  fill: var(--color-content-default3);
}

._1WS94sxMGd0TOvOjKez_pZ > svg {
  transform: rotate(-270deg);
}

.EIfLxofX8wNnCx7iqNT5D > svg {
  transform: rotate(270deg);
}

._39QOT7bypVjAz2qKOfuDhW > svg {
  transform: rotate(180deg);
}

._1kgCOTrnABkSw-psuMeNlY {
  display: inline-block;
  margin-right: 10px;
  text-transform: capitalize;
  border-radius: var(--borderRadius-select-normal);
}

._1kgCOTrnABkSw-psuMeNlY .react-select__control {
  width: 160px;
  height: 40px;
  background-color: var(--color-select-background);
  border-radius: var(--borderRadius-select-normal);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid var(--color-select-background);
  box-shadow: none;
}

._1kgCOTrnABkSw-psuMeNlY .react-select__control:hover {
  cursor: pointer;
  background-color: var(--color-select-background-hover);
  color: var(--color-select-background);
  border: none;
}

._1kgCOTrnABkSw-psuMeNlY
  .react-select__control:hover
  .react-select__single-value {
  cursor: pointer;
  color: var(--color-select-background);
}

._1kgCOTrnABkSw-psuMeNlY
  .react-select__control:hover
  .react-select__dropdown-indicator {
  cursor: pointer;
  color: var(--color-select-background);
}

._1kgCOTrnABkSw-psuMeNlY .react-select__value-container {
  padding: 2px 4px;
}

._1kgCOTrnABkSw-psuMeNlY .react-select__single-value {
  width: 160px;
  color: var(--color-select-primary1);
  font-size: var(--fontSize-s);
  height: 40px;
  line-height: 40px;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
}

._1kgCOTrnABkSw-psuMeNlY .react-select__menu-list {
  background-color: var(--color-select-background);
  border-radius: var(--borderRadius-select-normal);
  box-shadow: var(--boxShadow-select-normal);
}

._1kgCOTrnABkSw-psuMeNlY .react-select__menu {
  background-color: var(--color-select-background);
  color: var(--color-select-primary1);
  font-size: var(--fontSize-s);
  width: 160px;
}

._1kgCOTrnABkSw-psuMeNlY .react-select__indicator-separator {
  display: none;
}

._1kgCOTrnABkSw-psuMeNlY .react-select__input {
  display: inline-block;
}

._1kgCOTrnABkSw-psuMeNlY .react-select__dropdown-indicator {
  color: var(--color-select-primary1);
}

._1kgCOTrnABkSw-psuMeNlY .react-select__option {
  background-color: var(--color-select-background);
  color: var(--color-select-primary1);
}

._1kgCOTrnABkSw-psuMeNlY .react-select__option:hover {
  cursor: pointer;
  background-color: var(--color-select-background-hover);
  color: var(--color-select-background);
}

._1kgCOTrnABkSw-psuMeNlY .react-select__option--is-focused {
  background-color: var(--color-select-background-active);
  color: var(--color-select-primary1);
  font-weight: bold;
}

._1kgCOTrnABkSw-psuMeNlY .react-select__option--is-selected {
  background-color: var(--color-select-background);
  color: var(--color-select-highlight-active);
  font-weight: bold;
}

@media (max-width: 767px) {
  ._2sELgat9XPKVp24NbdSkPe {
    display: none;
  }

  ._19cexDVIaGdZ_5XdJu91up {
    display: none;
  }

  ._3M2svAkLEuuahIX9Ra9nxD {
    display: none;
  }

  /* .headerEpg {
    flex-direction: column;
  }

  .headerEpg .rightContainer {
    margin-left: 18px;
  } */
}

@media (max-width: 480px) {
  ._2SdL-elnkHx6Hy0mRkekK9 {
    flex-direction: column;
  }

  ._2SdL-elnkHx6Hy0mRkekK9 .VO3a96t60uNkkFpVLhaPP {
    margin-left: 18px;
  }

  ._3o8qWGJnvnWUE9BNejj-q6 fieldset details div {
    right: unset;
  }
}
