._2SSeo26Y9P6gFC5LivB4on{
  cursor: pointer;
  width: 100%;
  height: 100%;
  min-height: inherit;
  position: relative;
  display: flex;
  overflow: hidden;
}
._2FdCIuFKS7ydFUVHyqTzjx {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

._3yaDnOR6Rpj4oJE3hKlDzX {
  background-image: url(/assets/profile_default.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

._3wjw5SrHCWwVDs4R60kAhE {
  background-image: url(/assets/image_local_placeholder.png);
}

._3q_0kdcvprbrvxb9Vf_K0f {
  background-image: url(/assets/image_placeholder.png);
}

.RdPOukWbj01PN8AL7mCwU {
  background-color: var(--color-thumbnail-placeholder-bg);
  background-repeat: no-repeat;
  background-size: 70px;
  background-position: center;
}

.vS9o_hZOiJgiqQCWDd6UF {
  background-color: var(--color-content-overlay3);
  background-repeat: no-repeat;
  background-size: 70px;
  background-position: center;
}

._1rauys1SzBJZVz0esr7LWE {
  background-color: var(--color-nav-background);
  background-image: none;
}

.ZtPFo6Gl7MBXgjMRGLfe5 {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

._1jMDuOCwqvx7cAF5afP0m_ {
  display: none;
}

._2SSeo26Y9P6gFC5LivB4on::before,
._2Sq07c6cGNGY2J1jXEIltM::before,
._3x9_yxOEo6QObmg46_wjNX::before,
._2UeKQRV2qxKgbPuft3W9ci::before,
._2285fezHd73mFFOagCKdT-::before,
._1deT-BM7X_rlSMVo4dCYgd::before,
._120RqTUxYADEbmtRRibZDc::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  padding-bottom: 150%; /* 2:3 = 1.5 => 150% => */
}

._2Sq07c6cGNGY2J1jXEIltM::before {
  padding-bottom: 150%; /* 2:3 = 1.5 => 150% => */
}

._3x9_yxOEo6QObmg46_wjNX::before {
  padding-bottom: 56.25%; /* 16:9 = 0,5625 => 56.25% */
}

._2285fezHd73mFFOagCKdT-::before {
  padding-bottom: 200%; /* 2:4 = 2 => 200% */
}

._2UeKQRV2qxKgbPuft3W9ci::before {
  padding-bottom: 100%; /* 1:1= 1 => 100% */
}

._1deT-BM7X_rlSMVo4dCYgd::before {
  padding-bottom: 25%; /* 4:1 = 1/4 = 1,25 => 25% */
}

._120RqTUxYADEbmtRRibZDc {
  border-radius: 50%;
}

._2SSeo26Y9P6gFC5LivB4on span, ._2SSeo26Y9P6gFC5LivB4on img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.MrwH3Do4aD6urjRUdvs5q {
  margin: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
}

.Y0f5X6vBnzqc14X8DvCsV {
  position: absolute;
  z-index: -1;
  opacity: 1;
  width: 0;
  border-radius: 0;
  transition: all 1s ease-in-out;
}

._2ypMoN-a8XvmUBLmoqE-Rk {
  width: 100%;
}
._120RqTUxYADEbmtRRibZDc ._2ypMoN-a8XvmUBLmoqE-Rk {
  border-radius: 50%;
}

._2rQK5KkThmDnlI-OKqPb0H {
  filter: blur(25px);
}