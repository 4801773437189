:root {
  --playIconSize: 56px;
  --slideHeight-c1: 720px;
  --slideHeight-c2: 420px;
}

._28Dl3NPS3zwACWYFs-Xy9Y {
  position: relative;
}

._1dEKMUZjLUdMXFAgZK72Yh {
  position: absolute;
  z-index: 50;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
}
._1ttV2007XB6eovVwKtD_a5 {
  width: 80px;
  height: 80px;
  vertical-align: middle;
}
._2t3CkescM1stTGNRZ70xKx {
  position: absolute;
  display: block;
  z-index: 50;
  top: 50px;
  width: 100%;
}
._2PnwoP1QZrXgSnELjblFYm {
  margin-left: auto;
  margin-right: auto;
  display: block;
  z-index: 1;
}
._3HRynfVcYSKwnsIRwMUpZJ {
  background-color: var(--color-slideshow-primary2);
  width: 100%;
  height: auto;
  /* min-height: var(--slideHeight); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.05ms;
}

._97c-XY8-vo7IwaOJs9Y92 {
  min-height: var(--slideHeight-c2);
}

._1s2dV_ufB4RW8kutJeILgI {
  min-height: var(--slideHeight-c1);
}

._3HRynfVcYSKwnsIRwMUpZJ:before {
  content: "";
  display: block;
  height: 323px;
  width: 100%;
  object-fit: contain;
  /* background-image: var(--color-slideshow-overlay-before); */
}

._3HRynfVcYSKwnsIRwMUpZJ:after {
  content: "";
  display: block;
  height: 304px;
  object-fit: contain;
  position: absolute;
  /* background-image: var(--color-slideshow-overlay-after); */
  bottom: 0;
  width: 100%;
}

._3pAnLVuqC6s0ZxvuRqcEFq {
  cursor: pointer;
  z-index: 2;
}

._3pAnLVuqC6s0ZxvuRqcEFq,
._3pAnLVuqC6s0ZxvuRqcEFq > svg {
  width: var(--playIconSize);
  height: var(--playIconSize);
  position: absolute;
  top: calc(50% - var(--playIconSize) / 2);
  left: calc(50% - var(--playIconSize) / 2);
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
}

._3pAnLVuqC6s0ZxvuRqcEFq circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

._3pAnLVuqC6s0ZxvuRqcEFq:hover > svg {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
  transform: scale(1.1);
}

._1WuohGV_T6h4iI5Od7vpjz {
  position: absolute;
  bottom: 100px;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

._2VHOJniZLJRpYVj7yaK3El,
._3lzKoReWAGI9rogvHFddXE {
  z-index: 50;
  position: absolute;
  background-color: var(--color-secondary3);
  border-radius: 16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  bottom: 192px;
  display: inline-block;
  height: 25px;
  line-height: 11px;
}

._16ujCciDI_d1h-KayldG1M {
  position: absolute;
  display: inline-block;
  background-color: var(--color-secondary3);
  border-radius: 16px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 131px;
  padding: 5px 10px;
  min-height: 14px;
  line-height: 11px;
}

._2Lpm9OueN1Qzl7Qc3N1nmR > svg,
._7a2PNt1fnDR9zRrfLp5jh > svg,
._39lBIpvYg8EKrqYX9H9kJs > svg {
  width: 12px;
  padding-right: 5px;
  vertical-align: middle;
}

._39lBIpvYg8EKrqYX9H9kJs > svg {
  fill: var(--color-slideshow-highlight-hover1);
}

._2Lpm9OueN1Qzl7Qc3N1nmR path,
._7a2PNt1fnDR9zRrfLp5jh path {
  fill: var(--color-slideshow-primary1);
  stroke-width: 2px;
}

._3nD1CvQm6Ex9rmjdre3FUZ,
._1qP2GuY6xpNyAvHm_30P99,
.L4Qgyhd3czTaqemXhq1U9 {
  font-size: var(--fontSize-xs);
  font-weight: bold;
  color: var(--color-slideshow-highlight-hover1);
  text-transform: uppercase;
}

._1qP2GuY6xpNyAvHm_30P99,
.L4Qgyhd3czTaqemXhq1U9 {
  color: var(--color-slideshow-primary1);
}

.UPfWKj1zvWlHyXh8i_hVd {
  font-size: var(--fontSize-xl);
  color: var(--color-slideshow-primary1);
  font-weight: bold;
}

._2B3Bu8aJ-FxOXRQmNFDP9- {
  color: var(--color-slideshow-primary1);
  font-size: var(--fontSize-xxl);
  font-weight: bold;
  text-transform: uppercase;
  margin: 18px 0;
}

._1rWMeKpRSc3D-JZCe2GzJL {
  font-size: var(--fontSize-l);
  color: var(--color-slideshow-primary1);
}

._1aD-qSdWSxswkX87X5S9jZ {
  color: var(--color-slideshow-primary3);
  font-size: var(--fontSize-s);
  text-transform: uppercase;
  font-weight: bold;
}

._2v_qgVvym1fwZ0yr3fa_hR > svg {
  fill: var(--color-slideshow-primary3);
  width: 14px;
  height: 14px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

._1VXMlSV9JYyKaMx-lYcxgC > svg {
  fill: var(--color-slideshow-primary3);
  width: 11px;
  height: 11px;
  position: relative;
}

._2piEi2YYNCU3gGDH6MqXFT {
  color: var(--color-slideshow-primary3);
  font-size: var(--fontSize-s);
  font-weight: bold;
}

._2nrUCJK1kKLXbHI1OdvDzB {
  position: absolute;
  cursor: pointer;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: 107px;
  height: 30px;
  text-transform: uppercase;
  border-radius: 20px;
  border: var(--borderSize-button-slideshow-bordered) solid
    var(--color-button-slideshow-bordered-border);
  background-color: var(--color-button-slideshow-bordered-bg);
  color: var(--color-button-slideshow-bordered-font);
  font-size: var(--fontSize-s);
  z-index: 50;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

._3HRynfVcYSKwnsIRwMUpZJ:hover button {
  background-color: var(--color-button-slideshow-bordered-bg-hover);
  color: var(--color-button-slideshow-bordered-font-hover);
  border-color: var(--color-button-slideshow-bordered-border-hover);
}

._1lUlGB5eBhM-1rARxev7bZ {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

._1_RiKFRTQUi-DVqEAtZzps {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}

._1_RiKFRTQUi-DVqEAtZzps::before,
._1_RiKFRTQUi-DVqEAtZzps::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

._1_RiKFRTQUi-DVqEAtZzps::before {
  border-width: 20px;
}

._1_RiKFRTQUi-DVqEAtZzps::after {
  border-width: 20px;
  border-right-color: var(--color-border-right-padlock-container);
  border-top-color: var(--color-border-top-padlock-container);
}

.EzQvaidlw5DI_dWiYs9ui {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
}

.i_e1KQH8u7Pb_9PjUtx0u > svg {
  width: 16px;
  fill: var(--color-icon-playlock-fill);
}

._1AzGZgA9xWGN9EVdS2JWI9:hover ._2eYeA8Jmg-QwQqPE_LWT75 {
  display: none;
}

._1AzGZgA9xWGN9EVdS2JWI9:hover ._3rcqRnj7ZkMSlk1lAkYbnd {
  display: initial;
}

._2eYeA8Jmg-QwQqPE_LWT75,
._3rcqRnj7ZkMSlk1lAkYbnd {
  z-index: 1;
}

._1AzGZgA9xWGN9EVdS2JWI9 ._2eYeA8Jmg-QwQqPE_LWT75 {
  display: initial;
}

._1AzGZgA9xWGN9EVdS2JWI9 ._3rcqRnj7ZkMSlk1lAkYbnd {
  display: none;
}

._1OMvQA-PRxs3YdOctX_dBb {
  position: absolute;
  top: 100px;
  right: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
}

._32V-GqiO2R75W-AzBg0gil {
  fill: var(--color-thumbnail-highlight-hover1);
}

@media (min-width: 2000px) {
  :root {
    --slideHeight-c1: 1200px;
    --slideHeight-c2: 50vw;
  }
}

@media (max-width: 2000px) {
  :root {
    --slideHeight-c1: 800px;
    --slideHeight-c2: 50vw;
  }
}

@media (max-width: 1023px) {
  :root {
    --slideHeight-c1: 560px;
    --slideHeight-c2: 56vw;
  }

  ._2B3Bu8aJ-FxOXRQmNFDP9- {
    font-size: var(--fontSize-xl);
  }

  .UPfWKj1zvWlHyXh8i_hVd {
    font-size: var(--fontSize-l);
  }

  ._2VHOJniZLJRpYVj7yaK3El,
  ._3lzKoReWAGI9rogvHFddXE {
    bottom: 180px;
  }

  ._16ujCciDI_d1h-KayldG1M {
    bottom: 110px;
  }
}

@media (max-width: 767px) {
  :root {
    --slideHeight-c1: 510px;
    --slideHeight-c2: 110vw;
  }

  ._1e6GQaaRpL0nwiz9J09Rem {
    left: 20px;
    top: 20px;
  }

  ._1dEKMUZjLUdMXFAgZK72Yh {
    top: 15px;
  }
  ._2cUlshAdWJrsWJCk2uUNcV {
    margin-right: 10px;
  }

  ._2B3Bu8aJ-FxOXRQmNFDP9- {
    font-size: var(--fontSize-l);
    margin: 0;
    text-transform: uppercase;
  }

  .UPfWKj1zvWlHyXh8i_hVd {
    font-size: var(--fontSize-m);
  }

  ._1ttV2007XB6eovVwKtD_a5 img {
    height: 40px;
    width: 40px;
  }

  ._1rWMeKpRSc3D-JZCe2GzJL {
    font-size: var(--fontSize-m);
    margin-bottom: 5px;
  }

  ._16ujCciDI_d1h-KayldG1M {
    bottom: 80px;
  }

  ._2VHOJniZLJRpYVj7yaK3El,
  ._3lzKoReWAGI9rogvHFddXE {
    height: 20px;
    line-height: 3px;
    bottom: 159px;
  }

  .L4Qgyhd3czTaqemXhq1U9 {
    bottom: 140px;
  }

  ._1aD-qSdWSxswkX87X5S9jZ {
    font-size: var(--fontSize-s);
    margin: 0;
  }

  ._2nrUCJK1kKLXbHI1OdvDzB {
    bottom: 60px;
  }
}
._3vKL-WwlFw7zAmmUgwf_u_ {
  opacity: 1;
  transition: opacity 0.05ms;
}
._31FxxGB4aiJNJIEGAQ_-pO {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: opacity 2s ease-in-out;
  opacity: 1;
}
._1fumObYxCXix-gJp_fALOC {
  opacity: 0;
  transition: all 2s ease-out;
}
._3K-aty7cKVpCQ7x03BFJYZ {
  opacity: 1;
  transition: all 2s ease-in;
}

._3vKL-WwlFw7zAmmUgwf_u_:before {
  content: "";
  display: block;
  height: 323px;
  object-fit: contain;
  position: absolute;
  background-image: var(--color-slideshow-overlay-before);
  width: 100%;
  z-index: 1;
}

._3vKL-WwlFw7zAmmUgwf_u_:after {
  content: "";
  display: block;
  height: 304px;
  object-fit: contain;
  position: absolute;
  background-image: var(--color-slideshow-overlay-after);
  bottom: 0;
  width: 100%;
}

.gXRdi_4bALSI81FGMLg0B {
  opacity: 1;
  transition: opacity 0.05ms;
}

.gXRdi_4bALSI81FGMLg0B:before {
  content: "";
  display: block;
  height: 323px;
  object-fit: contain;
  position: absolute;
  background-image: var(--color-slideshow-overlay-before);
  width: 100%;
  z-index: 1;
}

._2SwA4utRryvcpRjrkzrig:before {
  content: "";
  display: block;
  height: 323px;
  object-fit: contain;
  position: absolute;
  background-image: var(--color-slideshow-overlay-before);
  width: 100%;
  z-index: 1;
}

.gXRdi_4bALSI81FGMLg0B:after {
  content: "";
  display: block;
  height: 304px;
  object-fit: contain;
  position: absolute;
  background-image: var(--color-slideshow-overlay-after);
  bottom: 0;
  width: 100%;
}

._2_PqHPHcUl5iOF2a03nQ2W::before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  backdrop-filter: blur(25px);
}