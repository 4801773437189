:root {
  --DevicesList-padding: 20px;
  --DevicesList-fontSize: var(--fontSize-m);
  --DevicesList-title-width: 180px;
  --DevicesList-title-height: 150px;
}

._253NbZiSTF25J0zP3n50ru {
  color: var(--color-content-highlight-hover);
}

._1ihCbXq6iAV-Uwwe5uX271 {
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

._3Ia2i6nveTWkR5PWoVSPwd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

._1fnZ9mPmtCKSOUwy_iseoV {
  text-align: center;
  margin-bottom: 50px;
}

._2Z-8mSz9OOFsB28jsox9pC {
  color: var(--color-content-default1);
  text-align: center;
  margin-bottom: 20px;
  font-size: var(--DevicesList-fontSize);
  font-weight: normal;
}

.kGBdb9Z0DQ3Zz2ZGqXU_q {
  width: var(--DevicesList-title-width);
  height: var(--DevicesList-title-height);
  line-height: var(--DevicesList-title-height);
  background-color: var(--color-content-overlay1);
  border-radius: 4px;
  color: var(--color-content-highlight-hover);
  font-size: var(--fontSize-xxxl);
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.jfwykL2Fxkn0QSf2dBPS {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto 20px;
  background-color: var(--color-content-overlay1);
  padding: var(--DevicesList-padding);
  box-sizing: border-box;
  position: relative;
  border-radius: 5px;
}

._1Enu1LBNJrCpdKOSt6EGcU {
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-content-default1);
  margin-right: 40px;
}

._2dwHHXu9w0Jif8YhYCc_vf {
  font-size: var(--DevicesList-fontSize);
  color: var(--color-content-default3);
  margin-right: 40px;
}

._2fM3h1fB5XbQHsI8FgEMI2 {
  cursor: pointer;
  position: absolute;
  top: calc(50% - 26px);
  right: 0;
  padding: 14px 10px;
  box-sizing: content-box;
  width: 25px;
  height: 25px;
}

._2fM3h1fB5XbQHsI8FgEMI2 g {
  stroke: var(--color-content-default1);
}

._2fM3h1fB5XbQHsI8FgEMI2 path {
    fill: var(--color-content-default1);
}

.Y4mrHpcaNI9VrJh6QuQgs {
  color: var(--color-content-default1);
  width: 330px;
  height: auto;
}

._2fU39CiBB79FzizAdny8T1 {
  font-size: var(--fontSize-m);
}

.RFVZ5eBSiR_B4zGgFsLns {
  color: var(--color-content-highlight-hover);
}

._3VPdelmGj2fgWkVESfHTC_ {
  float: left;
}

._9hG2Y1A5VbGLokcYVzfwa {
  float: right;
}

@media (max-width: 767px) {
  :root {
    --DevicesList-padding: 10px;
    --DevicesList-fontSize: var(--fontSize-s);
    --DevicesList-title-width: 130px;
    --DevicesList-title-height: 116px;
  }

  ._1ihCbXq6iAV-Uwwe5uX271 {
    margin-top: 10px;
  }

  .kGBdb9Z0DQ3Zz2ZGqXU_q {
    font-size: var(--fontSize-xxl);
    margin-bottom: 10px;
  }

  ._1Enu1LBNJrCpdKOSt6EGcU {
    font-size: var(--fontSize-m);
  }

  ._2fU39CiBB79FzizAdny8T1,
  .Y4mrHpcaNI9VrJh6QuQgs {
    font-size: var(--fontSize-s);
  }
}

._2LPBxX-sP8iul54-_Q3Mpo {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto 20px;
  padding: 13px 0px 13px 0px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  border-top: 1px solid var(--color-primary3);
  border-bottom: 1px solid var(--color-primary3);
}

._3ot2IOY4EbHnZNLL0nXSAC {
  width: 70%;
}

._1T90RAD7tNwp1DZCG6TPRm {
  width: 30%;
  padding-top: 21px;
}

.QLXDMX9qhj2Jl7Rw4s4mg {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary1);
  margin-bottom: 0px;
}

._3rf0I3fVeJJ8h7wieBc9N0 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary3);
  margin-top: 1px;
}

._2ZlNVPTr81vHsDpqS5QDLB {
  float: right;
  margin: 0px;
  min-width: 100%;
}

._3Ua7K1MGDwg1N84mY6HIOC {
  width: 100%;
  max-width: 1024px;
  margin: 52px auto 29px;
}

._3ByznK8O-ZCr5qoDdiCD0Q {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary-1);
}

._2dFXMEmFxBWLgdR_R3e7di {
  width: 330px;
  min-height: 47% !important;
  margin: 38px 0px 6px 0px;
  padding: 1px 0 16px;
  border-radius: 3px;
  background-color: var(--color-secondary3);
}

.ck6dQ6kFXG_Um5zd6u2wb {
  text-align: center;
}

._3m202TB9rOoNeHIrExG2IL {
  width: 100px;
  height: 100px;
  background-color: var(--color-brand-1);
}

._2mxzjubj1ZD6BFEycnSsj5 {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-primary2);
}

.zDT0ectNJCLEOYwNCDLyc {
  width: 270px;
  height: 40px;
  margin: 30px 0 0 0px;
  padding: 9px 0 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary2);
}

._2rgo6Yv_A83dx8H6gaJefk {
  margin: 43px 0px 6px 0px;
}

._39meekHEdnoGAgHzCTqtRu {
  margin: 6px 0px 6px 0px;
}

._2TLfbi8tBkI-xOqaBLDLGH {
  width: 391px;
  height: 25rem;
  margin: 20px auto;
  padding: 1px 30px 0px 30px;
  border-radius: 3px;
  background-color: var(--color-secondary3);
  min-height: 30% !important;
}

.gwv2wVLCQtzA8UqJxOGEX {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-error);
}

._2CFlrZxmZOd2IA7kp3tBmW {
  width: 200px;
  margin: 33px 0px 0 0px;
}

._1SFG4N9MSfDlpLS37j3Glx {
  width: 330px;
  margin: 38px 0px 0px 0px;
  padding: 1px 30px 0px 16px;
  border-radius: 3px;
  background-color: var(--color-secondary3);
}

._6zNgfec4_tuzJwYmkX53d > svg {
  width: 100px;
  height: 100px;
  margin: 30px 0px 40px 0px;
  background-color: var(--color-brand-1);
  fill: var(--color-secondary1);
}

._6zNgfec4_tuzJwYmkX53d circle {
  fill: var(--color-content-highlight-hover);
}

@media screen and (min-width: 320px) and (max-width: 1119px) {
  ._2LPBxX-sP8iul54-_Q3Mpo {
    flex-direction: column;
  }

  ._1T90RAD7tNwp1DZCG6TPRm {
    width: 100%;
  }
}
