._3LRbCd_wj_ko50ZiF1glSs {
  cursor: pointer;
  text-decoration: none;
  margin: 0 var(--marginBetweenThumbnails) 0 0;
  position: relative;
  transition: all 250ms cubic-bezier(.21,.43,.37,.99);
  min-height: 266px;
  min-width: 200px;
}
.pC2K1Rtqua0WVJfqHwnUw{
  margin: 0;
}
._2KX7OpwmoOY_MCntRUtlWv {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 50;
  width: 40px;
  height: 40px;
  text-align: left;
}
.MJz68WKFlaWulk-qkE-X6 {
  position: relative;
  z-index: 50;
  margin: auto;
  vertical-align: top;
  max-width: 40px;
  max-height: 40px;
}
._3HLhIeu7Or1mXbx8NZ25f_ {
  transform: scale3d(1.05, 1.05, 1.05);
  z-index: 10;
}

.ICrQcganYVGzAOpN8MeKh {
  position: relative;
  overflow: hidden;
}

._2FzjdIN5iAEZ6xV6_te9Vf {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 15;
  width: 40px;
  height: 40px;
}

._3BTO91dYRzvRfuXrkboaGz {
  position: absolute;
  bottom: 0;
  z-index: 50;
}

._3CfWIGD9W5jBAUeykFlJNc {
  background: var(--color-content-overlay3);
}
.oZdgYUVKG1AgwAHi2z6PR{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
}
._3DyZ2MHrI21j6IL0pZrwGd{
  fill: var(--color-thumbnail-highlight-hover1);
}

._3zTES52WcPvMsK1mqINaa5 {
  border-radius: var(--border-radius-thumbnail-small);
}

.r8_xOgvmfbPjOkJRQSdIW {
  border-radius: var(--border-radius-thumbnail-medium);
}

.fNnbsu7bP_hhqL7oX0vtq {
  border-radius: var(--border-radius-thumbnail-large);
}

.v5JDEeA2DqU-wC6Ep0LNj {
  box-shadow: var(--shadow-thumbnail-small);
}

.Zm-UBhua6MqdX5ATX7a2Y {
  box-shadow: var(--shadow-thumbnail-medium);
}

._1qbqRpkpd_hhROhQyiUPpW {
  box-shadow: var(--shadow-thumbnail-large);
}