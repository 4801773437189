:root {
  --title-fontSize: var(--fontSize-m);
  --subtitle-fontSize: var(--fontSize-s);
  --subtitle-margin: 0 0 20px;
  --synopsis-fontSize: var(--fontSize-s);
}

._1KQYW5J1YR1akUjCz4UI_Z {
  position: relative;
}
.bPicUA1n45vQ06e2Y7_EW {
  position: relative;
  border-radius: 5px;
  background-color: var(--charcoal);
  padding: 10px;
}
._3KApldy-Cmbz7uAkD6gFnO {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: flex-start;
  min-height: 90px;
}
._1H9-XNpQcfeRA748eWdCk7 {
  float: left;
  margin-right: 20px;
}
.yz5toAgZ1o0CicHt43YrU {
  float: left;
  margin-right: 20px;
}
._2FptQReBg061Wt53J8SHzn {
  position: absolute;
  bottom: 0;
  z-index: 50;
}

._34Mye_-FjVeXxSD0DGiRqq {
  text-decoration: none;
  margin: 0;
  text-overflow: ellipsis;
  font-size: var(--title-fontSize);
  font-weight: bold;
  color: var(--color-thumbnail-primary1);
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.SKCvzpMc6Uj1X9tybodaP {
  text-decoration: none;
  margin: 0 0 15px 0;
  text-overflow: ellipsis;
  font-size: var(--title-fontSize);
  font-weight: bold;
  color: var(--color-highlight-hover1);
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

._3QYik9DleYrsbe34gJa0Wx {
  font-size: var(--subtitle-fontSize);
  font-weight: normal;
  color: var(--color-thumbnail-primary3);
  margin: 0 0 10px;
}
/* .subtitleDate {
  font-size: var(--subtitle-fontSize);
  font-weight: 600;
  color: #ffca30;
  margin: 10px 0 10px 0;
} */
._1j0n-8EuB4-wRNKUJDLRAM {
  margin: 0;
  line-height: 16px;
  font-size: var(--fontSize-s);
  color: var(--color-thumbnail-primary1);
}

._1nVhz2LEv6UlwHLgTwXYdd {
  position: absolute;
  bottom: 0;
  right: 0;
  text-transform: uppercase;
  font-size: var(--fontSize-s);
}

._1nVhz2LEv6UlwHLgTwXYdd:hover {
  background-color: var(--color-button-bordered-border);
  color: var(--color-background-asset);
}

.W93fIftI-1c-NBtjj5ZpV {
  color: var(--color-highlight-hover1);
  margin: 0 0 10px;
}

@media (max-width: 768px) {
  :root {
    --title-fontSize: var(--fontSize-m);
    --subtitle-fontSize: var(--fontSize-s);
    --subtitle-margin: 0 0 10px;
    --synopsis-fontSize: var(--fontSize-s);
  }
  .bPicUA1n45vQ06e2Y7_EW {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .yz5toAgZ1o0CicHt43YrU {
    float: left;
    margin-right: 0;
  }
  ._3KApldy-Cmbz7uAkD6gFnO {
    min-height: 120px;
  }
}
