:root {
  --PageAsset-linkBackPosition: 40px;
  --PageAsset-linkBackPositionTop: 63px;
  --PageAsset-title-fontSize: var(--fontSize-xxl);
}

._1Va3WR6lBvQ5FP1Et_xNEt {
  background: var(--color-background-asset);
  height: 100%;
  width: 100%;
  overflow: auto;
}

._1KEOxOGsm7hDDqusmtveuv {
  position: absolute;
  top: 20px;
  left: var(--PageAsset-linkBackPosition);
  z-index: 20;
}
.EASi_9QWwAw0zI2CDkLGB {
  position: absolute;
  top: 20px;
  right: var(--PageAsset-linkBackPosition);
  z-index: 20;
}

._3dfbggCfl0q9hLSKngqwc_ {
  max-width: 1024px;
  min-height: 100vh;
  margin: 0 auto 50px;
  position: relative;
  padding: 0 var(--PageAsset-contentContainerPadding);
  z-index: 20;
}

._1IyrlraWEwp8cSxvo-8MdD,
._3T_F4PtpebJ0DLtbZsjfwk {
  padding-bottom: 0;
}

._2UYTcm8_RUr2yD_aQk9Bi {
  text-decoration: none;
  color: var(--color-content-default1);
}

._1-EhmtakcA0cuZdmPPplf0 {
  height: 24px;
  width: 24px;
}

._14W1JVV84INYIYMfdqYuj0 {
  font-weight: normal;
  font-size: var(--fontSize-s);
  cursor: pointer;
  vertical-align: middle;
  border-color: var(--color-content-default3);
  min-width: 200px;
}

._2NGRz02fjGfxNO1rBvKHmp {
  vertical-align: middle;
  min-width: 200px;
  border-color: var(--color-content-default3);
}

._2NGRz02fjGfxNO1rBvKHmp:hover {
  background: transparent;
}

._2Y0-W0-eOU9tgtjZgFZs_Q {
  font-size: var(--fontSize-s);
  font-weight: bold;
  color: var(--color-content-default1);
  margin: 40px 0 20px;
}

.oSD5yimA7FBof0d0EU89Y,
._1XmjLeHyIwa2TYsn29XD3Z {
  font-size: var(--fontSize-m);
  color: var(--color-content-default2);
}

._1XmjLeHyIwa2TYsn29XD3Z {
  margin-top: 20px;
}

._35cxFhmKDlfy9vCUZPdaWO {
  border-top: 1px solid var(--color-content-default3);
  margin: 40px 0;
  width: 100%;
}

._39X-bFkG8Mxr5nm2xuWdw1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.Jyq4aXMsKzu7YMOva0Nzq {
  display: inline-block;
}

.q5YffM8vLEEAeaioMwmvE {
  display: inline-block;
}

.mdDsBxhorbdMt2jj6ymkI {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100vh;
}

._3dNz-QeiosgezsVgZHhqWq {
  font-weight: normal;
  font-size: var(--fontSize-s);
  cursor: pointer;
  vertical-align: middle;
}

._26pYXR9gA1UWtHh3YjZszv {
  width: 24px;
  margin-right: 5px;
}

._26pYXR9gA1UWtHh3YjZszv path {
  fill: var(--color-background-asset) !important;
}

._2qqKvpU1RlEl4okXiKQy4F {
  max-width: 490px;
  color: var(--color-content-default1);
}

._2mycYsRDxZlvd5yid2Ps7z {
  font-size: var(--fontSize-s);
  font-weight: normal;
  text-decoration: none !important;
  padding-top: 10px;
}

._2TqgO2vo7PLwXT75gl65nR h1 {
  margin-top: 50px;
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-content-default1);
}

.H2sIKJz1AWe22uqJjKjwC {
  margin-bottom: 35px;
}

._302CO7UKOvCTYj8aJY90W2 > div:nth-child(2) > div:first-of-type {
  margin-left: -20px !important;
  padding-left: 20px !important;
}

@media (min-width: 768px) {
  .Jyq4aXMsKzu7YMOva0Nzq {
    display: inline-block;
  }
}
@media (max-width: 768px) {
  ._3dNz-QeiosgezsVgZHhqWq {
    margin-left: 5;
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  :root {
    --PageAsset-linkBackPosition: 20px;
    --PageAsset-title-fontSize: var(--fontSize-xl);
  }
  ._1KEOxOGsm7hDDqusmtveuv {
    top: var(--PageAsset-linkBackPosition);
    left: 14px;
  }
  ._1_8cLgot4bqyRcKDTkF887 {
    display: block;
    text-align: center;
    height: 110px;
    width: 60px;
  }

  .oKOvjk7gMe2KviTEkZHQd {
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    padding: 0;
  }

  ._1-EhmtakcA0cuZdmPPplf0,
  ._26pYXR9gA1UWtHh3YjZszv {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Jyq4aXMsKzu7YMOva0Nzq > div > a > span > span {
    top: 46%;
  }

  ._3tQDsn7ppPya2raMVgaPci {
    margin-left: -10px;
    margin-top: 10px;
  }

  ._2mycYsRDxZlvd5yid2Ps7z,
  .Jyq4aXMsKzu7YMOva0Nzq > div > div {
    padding-top: 2px;
  }

  .oSD5yimA7FBof0d0EU89Y,
  ._1XmjLeHyIwa2TYsn29XD3Z {
    font-size: var(--fontSize-s);
  }
}
