:root {
  --PlayerOverlay-animationDuration: 0.5s;
  --PlayerOverlay-iconDuration: 0.3s;
}
._2WKSaFu-hmFLODxMNXBkh0 {
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-player-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

._3R--LqlzVHnUqYkqvi0QqL {
  background: var(--color-content-progressbar-buffer-empty2);
  position: absolute;
  width: calc(100% - 100px);
  bottom: 100px;
  left: 50px;
  right: 50px;
  height: 6px;
  border-radius: 6px;
}

.vwONKYiys5XhZpTk2bnIG {
  position: absolute;
  bottom: 107px;
  color: var(--color-player-default1);
  right: 50px;
}

._1Is471byCejqUr08sbFcbU {
  position: absolute;
  bottom: 107px;
  color: var(--color-player-default1);
  left: 50px;
}

._2VCDFAjsJ5zvjR5FrEn5Hg {
  color: var(--color-player-default1);
  border: none;
  background: transparent;
  position: absolute;
  bottom: 30px;
  right: 50px;
  display: flex;
}

._2VCDFAjsJ5zvjR5FrEn5Hg > * {
  margin: 0 20px;
}

._29WRTg-zCk_nb3IFeRvKKX, ._29WRTg-zCk_nb3IFeRvKKX svg {
  cursor: pointer;
  fill: var(--color-player-default1);
  stroke: var(--color-player-default1);
  transition: fill var(--PlayerOverlay-iconDuration) ease-in-out,
    stroke var(--PlayerOverlay-iconDuration) ease-in-out;
  height: 24px;
}

._29WRTg-zCk_nb3IFeRvKKX:hover, ._29WRTg-zCk_nb3IFeRvKKX:hover svg {
  fill: var(--color-player-highlight-hover1);
  stroke: var(--color-player-highlight-hover1);
}


/* Overlay aninmation */
._2O9apMAvBa-uahMc6aMf8W {
  opacity: 0;
}

.pQgokTGtsNV7ZKI-k4BLS {
  opacity: 0;
}

._1myNThieFE11wx2a0i7R4E {
  transition: opacity var(--PlayerOverlay-animationDuration) ease-in-out;
  opacity: 1;
}

.Sb05_xqmZzoFbM5EaHGLF {
  opacity: 1;
}

.pCZCgIKSPQflkO_pwbP-9 {
  opacity: 1;
}

.v01ftiEfBRcajbBrJ9i3n {
  opacity: 0;
  transition: opacity var(--PlayerOverlay-animationDuration) ease-in-out;
}

.TobIDjHPYzs8Iyzh0syGM {
  opacity: 0;
  visibility: hidden;
  display: none;
}

._1AFvIYqd3GGMRktB_bwc1z {
  position: absolute;
  bottom: 155px;
  right: 50px;
  font-weight: normal;
  text-transform: inherit;
  width: auto;
  display: block;
  margin: 0;
  border-radius: 5px;
  background-color: white;
  color: black;
}

._1AFvIYqd3GGMRktB_bwc1z:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-color: white;
}

._1AFvIYqd3GGMRktB_bwc1z .gDzMglxyb1-0QCXUpU1Eu > svg {
  width: 10px;
  height: 10px;
  fill: black;
}

._1AFvIYqd3GGMRktB_bwc1z:hover .gDzMglxyb1-0QCXUpU1Eu > svg {
  fill: white;
}

._3TN3MukSvgvFjoQCRWhFhl {
  position: absolute;
  right: 44px;
  top: 30px;
  right: 50px;
  color: var(--color-player-default1);
  display: flex;
  align-items: center;
  width: max-content;
}

._3TN3MukSvgvFjoQCRWhFhl a {
  max-width: 220px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

._3TN3MukSvgvFjoQCRWhFhl:hover {
  color: var(--color-player-highlight-hover1);
  cursor: pointer;
}

._3TN3MukSvgvFjoQCRWhFhl:hover svg {
  stroke: var(--color-player-highlight-hover1);
}

._1BYoHJkFn4T5GoHX5HosK4 {
  display: inline-block;
  margin-left: 20px;
  font-size: var(--fontsize-l);
}

._2T-wM8FM16G9Sen5fh26as {
  color: var(--color-player-default1);
  font-weight: bold;
  margin: 0;
  text-decoration: underline;
}

._2T-wM8FM16G9Sen5fh26as:hover {
  color: var(--color-player-default1);
}

._2T-wM8FM16G9Sen5fh26as:active {
  color: var(--color-player-default1);
}

@media (max-width: 1023px) {
  ._1AFvIYqd3GGMRktB_bwc1z {
    bottom: 160px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .vwONKYiys5XhZpTk2bnIG,
  ._1Is471byCejqUr08sbFcbU {
    bottom: 110px;
  }

  ._3R--LqlzVHnUqYkqvi0QqL {
    bottom: 110px !important;
  }

  ._2VCDFAjsJ5zvjR5FrEn5Hg,
  ._1yjUt_abx3U37bmwctBGNi {
    bottom: 80px;
  }
}

@media (max-width: 767px) {
  ._2WKSaFu-hmFLODxMNXBkh0 {
    height: 95%;
  }

  ._3TN3MukSvgvFjoQCRWhFhl {
    right: 15px;
    top: 5px;
    display: inline-flex;
  }

  ._1BYoHJkFn4T5GoHX5HosK4 {
    margin-left: 10px;
  }

  ._2T-wM8FM16G9Sen5fh26as {
    font-size: var(--fontSize-s);
  }

  .vwONKYiys5XhZpTk2bnIG,
  ._1Is471byCejqUr08sbFcbU {
    font-size: var(--fontSize-xs);
  }

  ._3R--LqlzVHnUqYkqvi0QqL {
    width: calc(100% - 20px);
    left: 10px;
    right: 10px;
  }

  ._2VCDFAjsJ5zvjR5FrEn5Hg {
    right: 10px;
  }

  ._2VCDFAjsJ5zvjR5FrEn5Hg > * {
    margin: 0 10px;
  }

  ._1AFvIYqd3GGMRktB_bwc1z {
    bottom: 150px;
    right: 20px;
  }

  .vwONKYiys5XhZpTk2bnIG {
    right: 20px;
  }

  ._1Is471byCejqUr08sbFcbU {
    left: 20px;
  }
}

@media only screen and (max-height: 767px) and (orientation: landscape) {
  ._2WKSaFu-hmFLODxMNXBkh0 {
    height: 100%;
    overflow: hidden;
  }
  .vwONKYiys5XhZpTk2bnIG,
  ._1Is471byCejqUr08sbFcbU {
    bottom: 115px;
  }
  ._3R--LqlzVHnUqYkqvi0QqL {
    bottom: 100px;
  }
  ._2VCDFAjsJ5zvjR5FrEn5Hg {
    bottom: 65px;
  }
}
